import CryptoJS from "crypto-js";

const secretKey = process.env.VUE_APP_CRYPTO_KEY;

export const encryption = (uid) => {
  const encryptedData = CryptoJS.AES.encrypt(uid.toString(), secretKey).toString();
  const urlEncoded = encodeURIComponent(btoa(encryptedData));
  return urlEncoded;
};

export const decryption = (encryptedData) => {
  const decoded = atob(decodeURIComponent(encryptedData));
  const decryptedData = CryptoJS.AES.decrypt(decoded, secretKey).toString(CryptoJS.enc.Utf8);
  return decryptedData;
};
